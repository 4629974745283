import React, {Fragment, useEffect} from "react";
import {Spinner} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
interface Props {
    text:string;
}
export const Navigate: React.FC<Props> = ({text}) => {
    const navigate = useNavigate();
    useEffect(() => {
       // alert(text);
        navigate(text, { replace: true });
    },[text]);
    return <>
    </>;
};

