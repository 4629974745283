export interface QrScannnerLanguage{

    scanQR: string;
    loadingScanner: string;

}

export const languageQrScannerEnglish :QrScannnerLanguage ={
    scanQR: 'Scan the QR code on your policy with your mobile device to validate.',
    loadingScanner: 'Sending QR'

}

export const languageQrScannerSpanish :QrScannnerLanguage ={
    scanQR: 'Escanea el código QR de tu póliza con tu dispositivo móvil para  validarla.',
    loadingScanner: 'Enviando QR'
}