import axios from 'axios';
import { LocalStorageClass } from '../class/LocalStorageClass';
import environment from "../environments/environment";
import { Poliza } from '../models/public/Poliza';
import { useSelector } from 'react-redux';



const API = environment.APIVALIDADOR
const localStorageClass = new LocalStorageClass();

const headerJson = {
    Accept: 'application/json',
    'token': 'front-validador',
    env: environment.env,
    'X-API-KEY': environment.APIKEY,
    idLenguaje: localStorageClass.getLanguageId(),
    lang: localStorageClass.getLanguageCode()

}


const headersFormData = {
    'token': 'front-validador',
    'Content-Type': 'multipart/form-data',
    env: environment.env,
    'X-API-KEY': environment.APIKEY,
    idLenguaje: localStorageClass.getLanguageId(),
    lang: localStorageClass.getLanguageCode()
}

const updateHeader = () => {
    headerJson.lang = localStorageClass.getLanguageCode();
    headerJson.idLenguaje = localStorageClass.getLanguageId();

}


//mostrara los datos mediante la linea de validacion
export const getlineavalidacion = async (lineaValidacion: string) => {
    updateHeader();
    return await axios.get<Poliza>(`${API}/getByLine?line=` + lineaValidacion, { headers: headerJson });
}



//envia el documento xml
export const postDocVali = async (formData: FormData) => {
    updateHeader();
    return await axios.post<Poliza>(`${API}/getByXML/`, formData, { headers: headersFormData });
};



//descargara el archivo
export const getCarta = async (lineaValidacion: string) => {
    updateHeader();
    return await axios.get(`${API}/getURLCarta?lineaValidacion=` + lineaValidacion, { headers: headerJson });
}


//descargara el archivo
export const getPoliza = async (lineaValidacion: string) => {
    updateHeader();
    return await axios.get(`${API}/getURLPoliza?lineaValidacion=` + lineaValidacion, { headers: headerJson });
}


