export interface ValidadorLanguage{

    validcolateral: string;
    enterline: string;
    linevalition: string;
    buttonvalid:string;
    validating: string;
    textapp: string;
    deletebtn: string;
    filebtn: string;
    selct: string;
    capt:string
} 

export const languageValidadorEnglish :ValidadorLanguage ={
    validcolateral:'Validate your bond',
    enterline: 'Enter validation code or upload an XML file',
    linevalition: 'Validation code',
    buttonvalid: 'Validate',
    validating: 'Balidating',
    textapp: 'You can validate your bond through mobile devices using the QR code that appears on the policies and download our application "Liberty Fianzas Validator" via App Store (IOS) or Play Store (Android)' ,
    deletebtn: 'Delete file',
    filebtn: 'Choose file',
    selct: 'Selected file',
    capt:'en'

} 

export const languageValidadorSpanish :ValidadorLanguage ={
    validcolateral:'Valida tu fianza',
    enterline: 'Ingresa la línea de validación o sube un archivo XML',
    linevalition: 'Línea de validación',
    buttonvalid: 'Validar',
    validating: 'validando',
    textapp: 'Podrá realizar la validación de sus fianzas a través de dispositivos móviles utilizando el código QR que aparece en las  pólizas y descargando nuestra aplicación "Liberty Fianzas Validador" a través de App Store (IOS) o Play Store (Android)',
    deletebtn: 'Borrar archivo',
    filebtn: 'Subir archivo',
    selct: 'Archivo seleccionado',
    capt:'es'
} 