
import './QrScanner.scss';
import React, {Component} from "react";
import {connect} from "react-redux";
import {DefaultStateI} from "../../reducer/LanguageReducer";
import {DefaultStateIEnc} from "../../reducer/EncryptReducer";
import {RootStore} from "../../Store";
//import Html5QrcodePlugin from "../../components/public/html5-qr-code-plugin/Html5QrcodePlugin";
import * as animationData from '../../assets/lottie-files/62699-qr-code-scanner.json'
import {DecodeQr} from "../../models/public/DecodeQr";
import * as serviceValidador from "../../services/ServiceValidador";
import {Poliza} from "../../models/public/Poliza";
import {AxiosError} from "axios";
import { NavigateProps,useNavigate , RouteProps,RouterProps, HistoryRouterProps, BrowserRouterProps, PathRouteProps, IndexRouteProps} from "react-router-dom";
import swal from "sweetalert";
import {SpinnerLoading} from "../../components/public/spinner-loading/SpinnerLoading";
import {Navigate} from "../../components/public/navigate/Navigate";
import { encrypt } from '../../class/CommonClass';
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    },
};
interface QrScannerProps {
    language?: DefaultStateI,
    encrypt: DefaultStateIEnc
}

class QrScanner extends React.Component<QrScannerProps, QrScannerState>{


    constructor(props: any) {
        super(props);
        document.title = 'Escaner Código QR';

        this.state = {
            decodedResults:[],
            errorlinea: false,
            loadinglinea: false,
            noValidLine: false,
            redirectUrl: ''
        }

        this.onNewScanResult = this.onNewScanResult.bind(this);
    }

    render() {
        return <>
            <div className="container" id="qr-escanner">
                <div className="row">
                    <section className="col-12 col-sm-12 col-md-6 col-lg-6">
                        {(!this.state.loadinglinea)?(
                            <>
                           {/*     <Html5QrcodePlugin
                                fps={10}
                                qrbox={250}
                                disableFlip={false}
                                qrCodeSuccessCallback={this.onNewScanResult}/>*/}
                            </>

                        ):<div className="d-block mx-auto">
                            <SpinnerLoading text={this.props.language?.language?.qrScanner.loadingScanner} />
                        </div>}



                    </section>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 ">
                        <h5 className="scan-qr text-center">{this.props.language?.language?.qrScanner.scanQR} </h5>

                    </div>
                </div>
                {this.redirect(this.state.redirectUrl)}

            </div>

        </>
    }

    componentDidMount(){

    }

    onNewScanResult(decodedText: any, decodedResult: DecodeQr) {
        /*console.log(
            "App [result]", decodedResult);*/
        console.log("Qr decodedText", decodedResult.decodedText);
        // Extraemos la linea de validacion
        const linea = decodedResult.decodedText.substring(35, decodedResult.decodedText.length)
        // Redirigir
        this.getLineaValidacion(linea);
       // var win = window.open(decodedResult.decodedText, '_blank');
       // win?.focus();
        // let decodedResults = this.state.decodedResults;
        // decodedResults.push(decodedResult);
        this.setState((state, props) => {
            state.decodedResults.push(decodedResult);
            return state;
        });
    }

    public async getLineaValidacion(info: string) {
        this.setState({loadinglinea: true, errorlinea: false, noValidLine: false});

        try {
            const res = await serviceValidador.getlineavalidacion(encrypt(info, this.props.encrypt.key, this.props.encrypt.iv));
            const arrayValida: Poliza = res.data
            console.log(arrayValida)
            this.setState({loadinglinea: false, errorlinea: false});
            //agreagamos a la funcion para el localStorage
            this.saveLineaValida(arrayValida)
            //no recarga la pagina
            this.setState({redirectUrl: '/Formato'})



        } catch (error) {
            const err = error as AxiosError

            if (err.response) {
                this.setState({loadinglinea: false, errorlinea: true, noValidLine: true});
           //     navigate("/", { replace: true });
                swal('Oops', 'Verifica que tus datos sean correctos', 'warning').then(function () {
                    window.location.replace('');
                })
                alert();
            }

        }


    }

    public saveLineaValida(plist: Poliza) {
        //convertimos a string y lo guardamos con un nombre
        localStorage.setItem('localValida', JSON.stringify(plist));
    }

    public redirect(ulr: string){
        return  <Navigate text={ulr}/>
    }


}

const mapStateToProps = (state: RootStore)  => ({
    language: state.language,
    encrpt: state.encrypt
});

export default connect(mapStateToProps) (QrScanner);

interface QrScannerState{
    decodedResults: DecodeQr[];
    loadinglinea: boolean;
    errorlinea: boolean;
    noValidLine: boolean;
    redirectUrl: string;

}




