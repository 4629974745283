import React, { useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import './NavBar.scss';
import logo from '../../../assets/ico/PNG/LibertyFianzas_BLUE_RGB.png';
import {
    Modal, GridRow, GridCol
} from '@lmig/lmds-react';
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import SelectLanguage from '../select-language/SelectLanguage';


const NavBar = () => {

    const localStorageClass = new LocalStorageClass();

    // side bar
    const [openSideBar, setOpenSideBar] = useState(false);
    // modales
    const [openModalClose, setOpenModalClose] = useState(false);

    const open = () => {
        if (!openSideBar) {
            setOpenSideBar(true);
            // @ts-ignore
            document.getElementById('mySidenav').style.width = '250px';
            // @ts-ignore
            document.getElementById('coverNav').style.width = '100%';
        } else {
            close()
        }

    };

    const close = () => {
        setOpenSideBar(false);
        // @ts-ignore
        document.getElementById('mySidenav').style.width = '0';
        // @ts-ignore
        document.getElementById('coverNav').style.width = '0';


    };

    return (
        <div id="nav-bar">
            <Navbar fixed="top">
                <Navbar.Brand>


                    <img
                        src={logo}
                        width="112" height="53.3"
                        onClick={open}
                        className="d-inline-block align-top ico-liberty"
                        alt="Logo Liberty Fianzas"
                    />
                </Navbar.Brand>
                <Navbar.Collapse className="justify-content-end">

                    <Navbar.Text className="">
                        <div className="row">
                            <div className="col-2 language">
                                <SelectLanguage />
                            </div>
                        </div>

                    </Navbar.Text>
                </Navbar.Collapse>
            </Navbar>

            <div id="coverNav" onClick={close} className="background-nav"></div>

            {/*modal close*/}
            <Modal
                isOpen={openModalClose}
                size="medium"

                onClose={() => setOpenModalClose(false)}>

                <GridRow gutterSize="fixed-16" gutters>
                    <GridCol base={12} md={6}>
                    </GridCol>
                    <GridCol base={12} md={6}>

                    </GridCol>
                </GridRow>
            </Modal>

        </div>
    );
};

export default NavBar;
