export interface DefaultStateIEnc {
    key: string,
    iv: string
}

const defaultState: DefaultStateIEnc = {
    key: '12345678901234567890123456789012',
    iv: '1234567890123456'
};

const encryptReducer = (state: DefaultStateIEnc = defaultState): DefaultStateIEnc => {

    return state

};
export default encryptReducer;