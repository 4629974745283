import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './assets/css/fonts.scss';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import store from "./Store";
import {BrowserRouter, Link, Route, Routes} from "react-router-dom";
import Formato from "./views/formato/Formato";
import Validador from "./views/validador/Validador";
import NotFound from "./views/public/not-found/NotFound";
import Navbars from "./components/public/nav-bar/NavBar";
import QrScanner from "./views/qr-scanner/QrScanner";
import environment from "./environments/environment";
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <Provider store={store}>
          <BrowserRouter>

              <Navbars/>
              <Routes>

                  <Route path="/Formato" element={<Formato/>}  />
                  {(environment.env != "prod")?
                      <Route path="/qr" element={<QrScanner/>} />: null
                  }
                  <Route path="/" element={<Validador/>} />
                  <Route path='*'  element={<NotFound/>} />

              </Routes>
          </BrowserRouter>
      </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
