
import { LanguageModel } from "../models/public/LanguageModel";
import { initListLanguages } from "./CommonClass";

export class LocalStorageClass {
    // language
    public LANGUAGESELECT = 'LANGUAGESELECT';


    
    constructor() {
    }

    public setSomeString(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    public getSomeString(key: string): string {
        return <string>localStorage.getItem(key);
    }

    public setSomeBoolean(key: string, value: boolean): void {
        localStorage.setItem(key, value + '');
    }


    public getSomeBoolean(key: string): boolean {
        if (localStorage.getItem(key) == 'true') {
            return true;
        }
        return false;

    }

   


    public getLanguageId(): string {
        let LANGUAGE: LanguageModel = initListLanguages[0];

        if (this.getSomeString(this.LANGUAGESELECT)) {
            LANGUAGE = JSON.parse(this.getSomeString(this.LANGUAGESELECT))

            if (LANGUAGE.idLenguaje) {
                return String(LANGUAGE.idLenguaje);
            } else {
                return '';
            }

        } else {
            return String(LANGUAGE.idLenguaje);
        }
    }

    public getLanguageCode(): string {
        let LANGUAGE: LanguageModel = initListLanguages[0];

        if (this.getSomeString(this.LANGUAGESELECT)) {
            LANGUAGE = JSON.parse(this.getSomeString(this.LANGUAGESELECT))

            if (LANGUAGE.idLenguaje) {
                return String(LANGUAGE.lang);
            } else {
                return '';
            }

        } else {
            return String(LANGUAGE.lang);
        }
    }




}

