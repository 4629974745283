import React, { useEffect, useState} from 'react';
import flagMexico from '../../../assets/images/public/nav/flagMexico-modified.png';
import flagUnitedStates from '../../../assets/images/public/nav/flagUnitedSatates-modified.png';
import './SelectLanguage.scss';

import {Button, Heading, Modal, ModalHeader, ModalBody} from "@lmig/lmds-react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {getLanguage} from "../../../actions/SelectLanguageActions";
import {LanguageModel} from "../../../models/public/LanguageModel";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import {initListLanguages} from "../../../class/CommonClass";
import {useNavigate} from "react-router-dom";
interface SelectLanguageProps {

}

export const SelectLanguage: React.FC<SelectLanguageProps> = () => {

    const localStorageClass = new LocalStorageClass();

    const dispatch = useDispatch();
    // redux
    const languageState = useSelector((state: RootStore) => state.language);
    // modales
    const [openModalClose, setOpenModalClose] = useState(false);

    const [listLanguages, setlistLanguages] = useState<LanguageModel[]>([])

    const [selectLanguage, setSelectLanguage] =  useState<number>();

    useEffect(() => {

        let initialSatelistlan: LanguageModel[] = initListLanguages;

        let localStorageLanguageSelect: LanguageModel;

        if (localStorageClass.getSomeString(localStorageClass.LANGUAGESELECT)){

            localStorageLanguageSelect = JSON.parse(localStorageClass.getSomeString(localStorageClass.LANGUAGESELECT));

            for (let i of initialSatelistlan){

                if (i.idLenguaje == localStorageLanguageSelect.idLenguaje){
                    i.checked = true;
                    setSelectLanguage(localStorageLanguageSelect.idLenguaje);
                    dispatch(getLanguage(i.idLenguaje));
                    break;
                }
            }
        }else{
            // en caso de no encontrar ningun lenguaje seleccionado en local storage hay que seleccionar por defaul español

            if (initialSatelistlan.length>0){
                for (let i of initialSatelistlan){

                    if (i.idLenguaje == languageOptions.spanish){
                        i.checked = true;
                        setSelectLanguage(i.idLenguaje);
                        dispatch(getLanguage(i.idLenguaje));
                        localStorageClass.setSomeString(localStorageClass.LANGUAGESELECT,JSON.stringify(i));
                        break;
                    }
                }
            }

        }


        setlistLanguages(initialSatelistlan)



    },[]);

    // This function will be triggered when a radio button is selected
    const radioHandlerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log('select language')
        const index = parseInt(event.target.id);
        const languageSelect: LanguageModel = listLanguages![index];
        const value = parseInt(event.target.value);
        console.log(languageSelect)

        if (languageSelect.idLenguaje == languageOptions.spanish ||
            languageSelect.idLenguaje == languageOptions.english){
            setSelectLanguage(languageSelect.idLenguaje);
            setOpenModalClose(false);
            localStorageClass.setSomeString(localStorageClass.LANGUAGESELECT, JSON.stringify(languageSelect));
           // history.push('/cargando-idioma?url=' + history.location.pathname );
            dispatch(getLanguage(languageSelect.idLenguaje))
            window.location.replace('');

        }
    };

    return (
        <div id="select-language">

            <a className={"flags"}
               onClick={() => setOpenModalClose(true)}>

                {selectLanguage == languageOptions.spanish ? (
                    <img src={flagMexico} className={"img-circle"}  />
                ): null}

                {selectLanguage == languageOptions.english ? (
                    <img src={flagUnitedStates} className={"img-circle "} />
                ): null}

            </a>


            <Modal
                size="small"
                isOpen={openModalClose}
                onClose={() => setOpenModalClose(false)} >
                <ModalHeader>
                    <Heading tag="span" type="h4-light">
                        {languageState.language?.selectLanguage.titleModal!}
                    </Heading>
                </ModalHeader>
                <ModalBody >
                    <div className="modal-language">
                        <ul className="list-group list-group-flush">
                            {listLanguages?.map((i, index) => (

                                <li className="list-group-item" key={index}>

                                    <div  className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="languages"
                                            value={i.lang}
                                            id={String(index)}
                                            defaultChecked={i.checked}
                                            onChange={radioHandlerChange}
                                        />
                                        <label className="form-check-label" htmlFor={'language-'+i.idLenguaje}>
                                            <img src={i.image}
                                                 width={30}
                                                 height={30}
                                                 className="img-language img-thumbnail rounded-circle"/>
                                            &nbsp;
                                            {i.descripcion}
                                        </label>
                                    </div>
                                </li>

                            ))}

                        </ul>
                    </div>



                </ModalBody>
            </Modal>





        </div>
    );
};


export enum languageOptions {
    spanish = 1,
    english = 2
}
export default SelectLanguage;