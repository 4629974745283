import {ChangeEvent} from "react";
import {LanguageModel} from "../models/public/LanguageModel";
import flagMexico from "../assets/images/public/nav/flagMexico-modified.png";
import flagUnitedStates from "../assets/images/public/nav/flagUnitedSatates-modified.png";
import { useSelector ,connect} from 'react-redux';
import { RootStore } from '../Store';


const MAXSIZEUPLOADFILE = 10485760; // 10 MB = 10485760 Bytes (in binary)
export type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any >;

export const validFileXML = (e: ChangeEvent<HTMLInputElement>): boolean  => {
    if (e.target.files![0].type =='text/xml'){
        if (e.target.files![0].size <= MAXSIZEUPLOADFILE){
            return true;
        }
    }
return false;
};

export const initListLanguages: LanguageModel[] = [
    {
        idLenguaje: 1,
        lang:'es',
        descripcion: 'Español',
        image: flagMexico,
        checked: false
    },
    {
        idLenguaje: 2,
        lang:'en',
        descripcion: 'English',
        image: flagUnitedStates,
        checked: false
    }
];

//encriptador AES256
export const encrypt = (text: string , key: string, iv:string) => {
    //libreria
    var CryptoJS = require("crypto-js");
    //contraseña 
    key = CryptoJS.enc.Utf8.parse(key);
    //vector
    iv = CryptoJS.enc.Utf8.parse(iv);
    //Encriptación 
    var encrypted = CryptoJS.AES.encrypt(text, key, { iv: iv });
    encrypted = encrypted.toString(); 
    return encodeURIComponent(encrypted);
}