import { SelectLanguageEnglish } from "../components/public/select-language/SelectLanguageModel";
import { languageFormatoEnglish } from "../views/formato/FormatoLanguage";
import { languageValidadorEnglish } from "../views/validador/ValidadorLanguage";
import {LanguageModel} from "./SelectLanguageActionTypes";
import {languageQrScannerEnglish} from "../views/qr-scanner/QrScannerLanguage";

export const languageObjectEnglish: LanguageModel ={
    saludo: 'Welcome',
    // vistas
    validador: languageValidadorEnglish,
    formato: languageFormatoEnglish,
    qrScanner: languageQrScannerEnglish,
    // componentes
    selectLanguage: SelectLanguageEnglish,
}
