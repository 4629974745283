import React, {Fragment, useEffect} from "react";
import {Spinner} from "react-bootstrap";
import './SpinnerLoading.scss';
interface Props {
    text?:string;
}
export const SpinnerLoading: React.FC<Props> = ({text}) => {

    return <>
        
            <Spinner animation="border"
                     role="status"
                     variant="warning">

            </Spinner>
            

    </>;
};

