import axios, { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Gem, Link as LinkLiberty } from "@lmig/lmds-react";
import Form from 'react-bootstrap/Form';
import { IconDownload } from '@lmig/lmds-react-icons';
import './Formato.scss';
import Breadcrumb from 'react-bootstrap/esm/Breadcrumb';
import * as Service from "../../services/ServiceValidador";
import { RootStore } from '../../Store';
import { useSelector } from 'react-redux';
import {Link, useNavigate} from "react-router-dom";
import {useLocation} from "react-router";
import { encrypt } from '../../class/CommonClass';



export const Formato = () => {



  // redux para el lenguaje
  const languageState = useSelector((state: RootStore) => state.language.language?.formato);
  
  const key = useSelector((state: RootStore) => state.encrypt.key);
  const iv = useSelector((state: RootStore) => state.encrypt.iv);

  //useStates
  //usestates sping/error carta
  const [loadingCarta, setLoadingCarta] = useState<boolean>(false);
  const [errorCarta, setErrorCarta] = useState<boolean>(false);
  //usestates sping/error poliza
  const [loadingPoliza, setLoadingPoliza] = useState<boolean>(false);
  const [errorPoliza, setErrorPoliza] = useState<boolean>(false);
  //ayuda a mostrar uno u otro btn
  const [validabutton, setValidabutton] = useState<number>(1);
  //ayuda a mostrar uno u otro btn
  const [montovali, setMontovali] = useState<number>(1);
  //variables para mostrar datos
  var status, num_fianza, fecha, fiado, bene_cli, linea_vali: any, folio_vali, tipo_poliza: unknown, downl, monto, monto_movi: unknown;



  //funcion obtener valores localstorage
  function getDatos(): void {

    var storedata = localStorage.getItem('localValida');
    if (storedata == null) {
      console.log('vacio')
    } else {
      var data = JSON.parse(storedata)
      var objet = Object.values(data);

      num_fianza = (objet[0])
      fecha = (objet[1])
      fiado = (objet[2])
      monto = (objet[3])
      monto_movi = (objet[4])
      bene_cli = (objet[5])
      status = (objet[6])
      linea_vali = (objet[7])
      folio_vali = (objet[8])
      tipo_poliza = (objet[11])
      downl = (monto_movi)


    }
  }

  function deletelocal() {
    localStorage.removeItem('localValida');
  }
  //mandamos llamar la funcion para obtener los datos
  getDatos();

  //validamos tipo poliza

  const val = () => {
    var tipo = (tipo_poliza);
    if (tipo == true) {
      setValidabutton(1)
    } else {
      setValidabutton(2)
    }

  }

  useEffect(() => {
    // Dependiendo del tipo muestra a descargar los archivos
    val();
  });


  const mont = () => {
    
    if (monto_movi != null) {
      setMontovali(1)
    } else {
      setMontovali(2)
    }

  }

  useEffect(() => {
    // verifica si mostrar ambos campos de monto si no esta vacio
    mont();
  });


  //funcion para obtener Carta
  async function getByCarta(linea_vali: undefined): Promise<void> {

    setLoadingCarta(true)
    setErrorCarta(false)
    try {
      const res = await Service.getCarta(encrypt(String(linea_vali), key, iv));
      const urldata = JSON.stringify(res.data)
      const comillas = urldata.replace(/['"]+/g, '')
      var win = window.open(comillas, '_blank');
      win?.focus();

    } catch (error) {
      const err = error as AxiosError
      if (err.response) {
        console.log(err.response.status)
        console.log(err.response.data)
        setLoadingCarta(false)
        setErrorCarta(true)
      }
    }


  }

  //funcion para obtener Poliza
  async function getByPoliza(linea_vali: undefined) {

    setLoadingPoliza(true)
    setErrorPoliza(false)
    try {
      const res = await Service.getPoliza(encrypt(String(linea_vali), key, iv));
      const urldata = JSON.stringify(res.data)
      const comillas = urldata.replace(/['"]+/g, '')

      var win = window.open(comillas, '_blank');
      win?.focus();
      setLoadingPoliza(false)

    } catch (error) {
      const err = error as AxiosError
      if (err.response) {
        console.log(err.response.status)
        console.log(err.response.data)
        setLoadingPoliza(false)
        setErrorPoliza(true)
      }
    }


  }


  //onclick btn poliza y carta

  const downlcarta = async () => {
    getByCarta(linea_vali);
  }
  const downlpoliza = async () => {
    getByPoliza(linea_vali);
  }

  const navigate = useNavigate();
  //Inicia los componentes de la vista
  return (
    <>

      <div className="container" id="formato">
        <div className="bread">
          <Breadcrumb>
            <Breadcrumb.Item href="/" onClick={deletelocal} >


                <LinkLiberty
                    onClick={() => {
                      navigate(-1)// redirigir a la página anterior
                    }}
                    variant="standalone">

                  Home
                </LinkLiberty>

            </Breadcrumb.Item>

            <Breadcrumb.Item active>
              Validador</Breadcrumb.Item>
          </Breadcrumb>

        </div>
        <div className="row">
          <div className="col-12" >

            <div className="card " >
              <div className="card-header">
                {languageState?.info}
              </div>
              <div className="card-body" id='card'>
                <h6 className="card-subtitle mb-2 ">

                </h6>

                <br />
                <div className="col-xs-4">
                  <Form.Label className='label-input' htmlFor="inputPassword5"> {languageState?.numfian} </Form.Label>
                  <input type="text" className="form-control text-muted" id="numFianza" value={num_fianza} disabled />
                </div>
                <br />

                <Form.Label className='label-input' htmlFor="inputPassword5"> {languageState?.date} </Form.Label>
                <input type="text" className="form-control text-muted" id="fechaPoliza" value={fecha} disabled />
                <br />


                <Form.Label className='label-input' htmlFor="inputPassword5"> {languageState?.client} </Form.Label>
                <input type="text" className="form-control text-muted" id="fiado" value={fiado} disabled />
                <br />

                {(montovali == 1) ? (
                  <div className="col-xs-4">
                    <Form.Label className='label-input' htmlFor="inputPassword5"> {languageState?.montomovi} </Form.Label>
                    <input type="text" className="form-control text-muted" id="fiado" value={downl} disabled /> <br />
                  </div>
                 

                ) : null
                }

                
                {(montovali == 1 || montovali == 2 ) ? (
                  <div className="col-xs-4">

                    <Form.Label className='label-input' htmlFor="inputPassword5"> {languageState?.monto} </Form.Label>
                    <input type="text" className="form-control text-muted" id="beneficiado" value={monto} disabled />  </div>

                ) : null
                }
                <br />


                <Form.Label className='label-input' htmlFor="inputPassword5"> {languageState?.benefited} </Form.Label>
                <input type="text" className="form-control text-muted" id="beneficiado" value={bene_cli} disabled />
                <br />

                <div className="col-xs-4">
                  <Form.Label className='label-input' htmlFor="inputPassword5"> {languageState?.status} </Form.Label>
                  <input type="text" className="form-control text-muted" id="numFianza" value={status} disabled />
                </div>
                <br />

                <Form.Label className='label-input' htmlFor="inputPassword5"> {languageState?.validationline} </Form.Label>
                <input type="text" className="form-control text-muted" id="lineaValidacion" value={linea_vali} disabled />
                <br />

                <Form.Label className='label-input' htmlFor="inputPassword5"> {languageState?.validationsheet} </Form.Label>
                <input type="text" className="form-control text-muted" id="folioValidacion" value={folio_vali} disabled />
                <br />




                <div className="btn-toolbar mb-3" role="toolbar" aria-label="Toolbar with button groups">

                  {(validabutton == 1) ? (
                    <div className="input-group">
                      <Button variant="primary" onClick={downlpoliza}>
                        <IconDownload
                          size="16"
                        />&nbsp; {languageState?.policy}
                      </Button>
                    </div>

                  ) : null
                  }
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  {(validabutton == 2 || validabutton == 1) ? (
                    <div className="btn-group mr-2" role="group" aria-label="First group">
                      <Button variant="primary" onClick={downlcarta}  >
                        <IconDownload
                          size="16"
                        /> &nbsp; {languageState?.letter}
                      </Button>
                    </div>
                  ) : null
                  }
                </div>


              </div>

            </div>
          </div>


        </div>
      </div>


    </>
  )
}

export default Formato;
