import {useState, ChangeEvent, useEffect} from 'react';
import './Validador.scss';
import { Alert, AlphanumericInput, Button, FieldGroup, Form, GridCol, GridRow } from "@lmig/lmds-react";
import { IconFolder, IconSearch, IconTrash } from '@lmig/lmds-react-icons';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSelector } from 'react-redux';
import { RootStore } from '../../Store';
import * as serviceValidador from "../../services/ServiceValidador";
import { AxiosError } from "axios";
import { Poliza } from '../../models/public/Poliza';
import { validFileXML, encrypt } from '../../class/CommonClass';
import { useNavigate } from 'react-router';
import 'bootstrap/dist/css/bootstrap.min.css';
import swal from 'sweetalert';
import {Spinner, Tab, Tabs} from 'react-bootstrap';
import { useForm } from 'react-hook-form';

//Inicia vista principal
export const Validador = () => {

    // redux selccionar el lenguaje
    const languageState = useSelector((state: RootStore) => state.language.language?.validador);
    
    const key = useSelector((state: RootStore) => state.encrypt.key);
    const iv = useSelector((state: RootStore) => state.encrypt.iv);
    //reCaptcha y validacion
    const [lineavalido, cambiarlineavalido] = useState(true);

    function onChange(value: any) {
        cambiarlineavalido(false)

    }
    //constantes guardan cambios
    const [info, setInfo] = useState('');
    const [loadingXML, setLoadingXML] = useState<boolean>(false);
    const [errorXML, setErrorXML] = useState<boolean>(false);
    const [loadinglinea, setLoadinglinea] = useState<boolean>(false);
    const [errorlinea, setErrorlinea] = useState<boolean>(false);
    const initformData = new FormData();
    const [validalinea, setValidalinea] = useState<number>(1);
    const [formData, setFormData] = useState<FormData>(initformData);
    const [valida, setValida] = useState<Poliza>();
    const [poliza, setPoliza] = useState<Poliza>();
    const navigate = useNavigate();
    const [envio, setEnvio] = useState<boolean>(false);
    const [sendigValidation, setSendingValidation] = useState<boolean>(false);
    const { register } = useForm();

    //validamos para ocultar input / inputfile
    function handleInpu(event: { target: { value: any; }; }) {
        const datos_valida = event.target.value;
        setInfo(datos_valida);

        if (datos_valida.length > 0) {
            setValidalinea(2);
        }

        else
            setValidalinea(1);

    }

    //Se elimina el archivo para poder mostrar de nuevo el input
    const deleteInputFile = () => {
        setValidalinea(1)
        setFormData(initformData)
        var clon = (initformData)
        //redireccionamos a la pagina principal
        window.location.replace('');

    }

    //Creamos un nuevo formData
    const handleInputFile = (e: ChangeEvent<HTMLInputElement>) => {

        if (validFileXML(e)) {
            const formDataAux = formData;

            formDataAux.append('xml', e.target.files![0]);
            setFormData(formDataAux)
            setValidalinea(3)




        }
    }

    //Hacemos la consulta get y redireccionamos
    async function file(info: string) {
        setLoadinglinea(true)
        setErrorlinea(false)
        setEnvio(false)
        setSendingValidation(true);
        try {
            const res= await serviceValidador.getlineavalidacion(encrypt(info, key, iv));
           // const arrayValida: Poliza = res.data;
            setValida(res.data)
            //console.log(arrayValida)
            setSendingValidation(false);
            //agreagamos a la funcion para el localStorage
            setLineaValida(res.data)
            //no recarga la pagina
            navigate("/Formato", { replace: true });


        } catch (error) {
            const err = error as AxiosError

            if (err.response) {

                navigate("/", { replace: true });
                setLoadinglinea(false)
                setSendingValidation(false);
                cambiarlineavalido(true)
                alert();
            }

        }


    }
    //Guarda datos de la linea en el localStorage
    function setLineaValida(plist: Poliza) {
        //convertimos a string y lo guardamos con un nombre
        localStorage.setItem('localValida', JSON.stringify(plist));
    }

    //Hacemos la consulta get formdata  y redireccionamos
    async function postFileValidacion(formData: FormData) {
        setLoadingXML(true)
        setErrorXML(false)
        try {
            const res = await serviceValidador.postDocVali(formData)
            const arrayPoliza: Poliza = res.data
            setPoliza(arrayPoliza)
            setDocument(arrayPoliza)
            setLoadingXML(false)
            navigate("/Formato", { replace: true });

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                // quitar el spiner de loading
                alert();
                setLoadingXML(false);
                setErrorXML(true)
            }
        }
    }
    //Guardamos datos de la peticion en el localStorage
    function setDocument(plist: Poliza) {
        localStorage.setItem('localValida', JSON.stringify(plist));
    }

    //creamos alerta
    function alert() {
        swal('Oops', 'Verifica que tus datos sean correctos', 'warning').then(function () {
            window.location.replace('');
        })
    }

    //funcion onclick  para conocer cual peticion hara
    const submitHandler = async () => {
        //  e.preventDefault();

        if (validalinea == 2) {
            //validamos para enviar linea
            file(info)
        }
        if (validalinea == 3) {
            //enviar xml
            postFileValidacion(formData);

        }

    }

    const ios = () => {

        var urlios = 'https://apps.apple.com/mx/app/liberty-fianzas-validador/id1527671098'
        var win = window.open(urlios, '_blank');
        win?.focus();

    }

    const android = () => {

        var urlios = "https://play.google.com/store/apps/details?id=com.libertyfianzas.libertyfianzasvalidador&hl=es-419"
        var win = window.open(urlios, '_blank');
        win?.focus();

    }

    function setCaptchaLang(recaptchaContainer: { querySelector: (arg0: string) => any; }, lang: string) {
        lang = lang || "es";

        // 1. Busque el iframe de ReCaptcha
        const iframeGoogleCaptcha = recaptchaContainer.querySelector('iframe');

        // 2. Recuperar el idioma actual
        const currentLang = iframeGoogleCaptcha.getAttribute("src").match(/hl=(.*?)&/).pop();

        // 3. Verifique si el idioma que desea configurar es diferente al actual
        if (currentLang !== lang) {
            // 4. Si es así, cámbialo
            iframeGoogleCaptcha.setAttribute(
                "src",
                iframeGoogleCaptcha.getAttribute("src").replace(
                    /hl=(.*?)&/,
                    'hl=' + lang + '&'

                )
            );

           
        }
    }


    useEffect(() => {


    }, []);

    return (
        

        <>
            <div className="container">
                <div className="row ">
                    <div className="col-12 col-md-6">
                        <Form id='forms' onSubmit={submitHandler} encType="form-data" >
                            <div className="row">
                                <div className="col-md-12">



                                    <FieldGroup labelStyle="h2-light" labelVisual={languageState?.validcolateral} >

                                        <>
                                            <h6> {languageState?.enterline} </h6>

                                            {
                                                (validalinea == 1 || validalinea == 2) ? (
                                                    <AlphanumericInput
                                                        labelVisual={languageState?.linevalition}
                                                        id='linea'
                                                        className="w-100"
                                                        name="linea"
                                                        onChange={handleInpu}
                                                        required={true}
                                                    />



                                                ) : null
                                            }
                                            <br />
                                            {
                                                (validalinea == 1 || validalinea == 3) ? (
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="custom-input-file col-12 col-md-12 col-sm-6 ">
                                                                <label className="custom-file-upload" >

                                                                    <IconFolder
                                                                        size="16"
                                                                        color='inverse' /> &nbsp;
                                                                    <input type="file" accept=".xml" onChange={handleInputFile} />
                                                                    {languageState?.filebtn}

                                                                </label>
                                                            </div>
                                                            {(validalinea == 3) ? (
                                                                <Alert highlightType="positive">
                                                                    {languageState?.selct}
                                                                </Alert>
                                                            ) : null}
                                                            <br />
                                                        </div>


                                                        {(validalinea == 3) ? (
                                                            <div className="col-12 text-center delete-file" >
                                                                <br />
                                                                <button type="button" className="btn btn-outline-danger" onClick={deleteInputFile}><IconTrash size="16" color="negative" /> &nbsp; {languageState?.deletebtn}</button>
                                                                <br />
                                                            </div>
                                                        ) : null}

                                                    </div>
                                                ) : null
                                            }
                                            <br />
                                            <div className="recaptcha" >
                                                <br />
                                                <br />
                                                <ReCAPTCHA
                                                    sitekey="6LeOcoEfAAAAAEitqqYoz1FAKq379kLCl8kOoQi6"
                                                    onChange={onChange}
                                                    hl={languageState?.capt}

                                                />
                                            </div>
                                            <br />
                                            {(sendigValidation)?
                                                <Button type='submit' dynamicWidth variant="secondary"  disabled={true} >
                                                        <Spinner animation="border" variant="light" size="sm" /> &nbsp;
                                                        {languageState?.validating}

                                                </Button>:
                                                <Button type='submit' dynamicWidth variant="primary" onClick={submitHandler} disabled={lineavalido} >
                                                    <IconSearch
                                                        size="16"
                                                    />&nbsp;{languageState?.buttonvalid}

                                                </Button>
                                            }


                                        </>

                                    </FieldGroup>

                                </div>
                            </div>


                        </Form>


                    </div>
                    <div className="col-12 col-md-6">
                        <img src='https://public.libertymutual-cdn.com/lm-media-assets/content-images/homepage/renters_size-381x287.svg' className="illustration mx-auto d-block" />
                        <p > {languageState?.textapp} </p>
                        <div className="row">
                            <div className="col-2">

                            </div>
                            <div className="col-4">
                                <a onClick={android} href="">
                                    <img src="https://public.libertymutual-cdn.com/lm-media-assets/ins-resources/google-play.png" alt="" className="w-100" />
                                </a>
                            </div>
                            <div className="col-4">
                                <a onClick={ios} href="">
                                    <img src="https://public.libertymutual-cdn.com/lm-media-assets/ins-resources/app-store.png" alt="" className="w-100" />
                                </a>
                            </div>


                            <div className="col-2">

                            </div>

                        </div>


                        &nbsp; &nbsp; &nbsp;



                    </div>
                </div>
                       { /* se comenta el boton*/  }                           
                {/*(environment.env != "prod")?
                    <div className="fab-whats-app">
                        <Link to="/qr" className="text-center d-block mx-auto">
                            <Lottie options={defaultOptions}
                                    height={60}
                                    width={60}
                                    isStopped={false}
                                    isPaused={false}/>
                        </Link>

                    </div>: null
                                                */}

            </div>

        </>

    );

    function newFunction() {
        try {
        }
        catch (error) { }
    }

}

export default Validador;