export interface FormatoLanguage{

    info: string;
    numfian: string;
    date: string;
    client: string;
    benefited:string;
    validationline: string;
    validationsheet: string;
    letter: string;
    policy: string;
    status:string;
    monto: string;
    montomovi: string;
    home: string;
    validator: string;

} 

export const languageFormatoEnglish :FormatoLanguage ={
    info:'Bond Information',
    numfian:'Bond number',
    date: 'Policy date',
    client: 'Principal (Client)',
    benefited: 'Beneficiary',
    validationline: 'Validation Code',
    validationsheet: 'Folio number',
    letter: 'Authenticity Letter ',
    policy: 'Policy',
    status: 'Status',
    monto: 'Consolidated Amount',
    montomovi: 'Movement Amount',
    home: 'Home',
    validator: 'Validator'

} 

export const languageFormatoSpanish :FormatoLanguage ={

    info: 'Información Fianza',
    numfian:' Número de la fianza',
    date: 'Fecha póliza',
    client: 'Fiado (Cliente)',
    benefited: 'Beneficiario',
    validationline: 'Línea de Validación',
    validationsheet: 'Folio de Validación',
    letter: 'Carta Autenticidad',
    policy: 'Póliza',
    status: 'Estatus',
    monto: 'Monto Afianzado',
    montomovi: 'Monto Movimiento',
    home: 'Inicio',
    validator: 'Validador'
} 