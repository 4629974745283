
import { SelectLanguageModel } from "../components/public/select-language/SelectLanguageModel";
import { FormatoLanguage } from "../views/formato/FormatoLanguage";
import { ValidadorLanguage } from "../views/validador/ValidadorLanguage";
import {QrScannnerLanguage} from "../views/qr-scanner/QrScannerLanguage";

export const SELECT_LANGUAGE_ENGLISH = "SELECT_LANGUAGE_ENGLISH";
export const SELECT_LANGUAGE_SPANISH = "SELECT_LANGUAGE_SPANISH";


export type LanguageModel = {
    saludo: string;
    // vistas
    validador: ValidadorLanguage;
    formato: FormatoLanguage;
    qrScanner: QrScannnerLanguage;
    // componentes
    selectLanguage: SelectLanguageModel;
}


export interface LanguageEnglish {
    type: typeof SELECT_LANGUAGE_ENGLISH,
    payload: LanguageModel
}

export interface LanguageSpanish {
    type: typeof SELECT_LANGUAGE_SPANISH,
    payload: LanguageModel
}



export type SelectLanguageDispatchTypes = LanguageEnglish | LanguageSpanish