import { combineReducers } from "redux";
import languageReducer from "./LanguageReducer";
import encryptReducer from './EncryptReducer';


const RootReducer = combineReducers({
    language: languageReducer,
    encrypt: encryptReducer,

});

export default RootReducer