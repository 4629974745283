import {LanguageModel} from "./SelectLanguageActionTypes";

import { NavBarLanguageSpanish } from "../components/public/nav-bar/NavBarLanguageModel";
import { languageValidadorSpanish } from "../views/validador/ValidadorLanguage";
import { SelectLanguageSpanish } from "../components/public/select-language/SelectLanguageModel";
import { languageFormatoSpanish } from "../views/formato/FormatoLanguage";
import {languageQrScannerSpanish} from "../views/qr-scanner/QrScannerLanguage";

export const languageObjectSpanish: LanguageModel = {
    saludo: 'Bienvenido',
    // vistas
    validador: languageValidadorSpanish,
    formato: languageFormatoSpanish,
    qrScanner: languageQrScannerSpanish,
    // componentes
    selectLanguage: SelectLanguageSpanish,
    
}